import React, {useState} from 'react';
import {doc, setDoc} from "firebase/firestore";
import 'react-quill/dist/quill.snow.css'; // Import the CSS file for the rich text editor

import './Form.css';
import ReactQuill from "react-quill";
import {db} from "../App";
import LocationPicker from "../components/LocationPicker";
import XmlDragAndDrop from "../components/XmlDragNDrop"; // Import the CSS file

function Form() {
    const [dob, setDob] = useState('');
    const [time, setTime] = useState('');
    const [place, setPlace] = useState('');
    const [xml, setXML] = useState('');
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [placeCoords, setPlaceCoords] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);



    const handleSubmit = async (event) => {
        event.preventDefault();
        const id = Date.now().toString();
        const formData = {dob, time, place, placeCoords, question, answer, xml};

        setIsSubmitting(true);
        setDoc(doc(db, "form-data", id), formData).then((val)=>{
            setIsSubmitting(false)
            setDob('');
            setTime('');
            setPlace('');
            setQuestion('');
            setAnswer('');
            setXML('');
        }).catch(e=>{
            setIsSubmitting(false)
            console.log('error occurred: ', e)
        });

    };

    const handlePlaceChange = async (location) => {
        setPlaceCoords(location)
    };

    const onXMLDropped = (data)=>{
        const {dob, place, placeCoords, xml} = data;
        setDob(dob)
        setPlace(place);
        setPlaceCoords(placeCoords);
        setXML(xml);
    }

    if (isSubmitting){
        return <div>Submitting ...</div>
    }

    return (
        <form className="form-container" onSubmit={handleSubmit}>
            <div className="form-field">
                <XmlDragAndDrop onDropped={onXMLDropped}/>
                {/*<label htmlFor="dob">Date of Birth:</label>*/}
                {/*<input*/}
                {/*    type="date"*/}
                {/*    id="dob"*/}
                {/*    name="dob"*/}
                {/*    value={dob}*/}
                {/*    onChange={(event) => setDob(event.target.value)}*/}
                {/*    required*/}
                {/*/>*/}
            </div>
            {/*<div className="form-field">*/}
            {/*    <label htmlFor="place">Place:</label>*/}
            {/*    <LocationPicker onLocationSelect={(lat, long, locationName) => {*/}
            {/*        handlePlaceChange({lat, long, locationName})*/}
            {/*    }}/>*/}
            {/*</div>*/}
            <div className="form-field">
                <label htmlFor="question">Question:</label>
                <input
                    type="text"
                    id="question"
                    name="question"
                    className={"question"}
                    value={question}
                    onChange={(event) => setQuestion(event.target.value)}
                    required
                />
            </div>
            <div className="form-field">
                <label htmlFor="answer">Answer</label>
                <ReactQuill className={"answer"} value={answer} onChange={setAnswer}/>
            </div>
            <button type="submit">Submit</button>
        </form>
    );
}

export default Form;
