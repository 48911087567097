import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Link, Route, Routes, useNavigate} from "react-router-dom";

import FormEntry from "./pages/FormEntry";
// Import the functions you need from the SDKs you need
import {initializeApp,} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getFirestore} from "firebase/firestore";
import ReportWebVitals from "./reportWebVitals";
import ViewData from "./pages/FormEntires";


const firebaseConfig = {
    apiKey: "AIzaSyD_aw2SR1taAecUZ4homqraCYC4fiXNbmc",
    authDomain: "astro-data-collect.firebaseapp.com",
    projectId: "astro-data-collect",
    storageBucket: "astro-data-collect.appspot.com",
    messagingSenderId: "789809138049",
    appId: "1:789809138049:web:9474984eeb55f3751c8080",
    measurementId: "G-MT2BBLQQLE"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <div>
                    <Link to="entries">All Entries</Link>
                </div>
                <Routes>
                    <Route
                        index
                        element={
                            <FormEntry/>
                        }
                    />
                    <Route
                        path="entries"
                        element={
                            <ViewData/>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
