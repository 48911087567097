import React, { useState } from 'react';
// Julian to Gregorian date conversion function
function extractBirthInfo(xmlData) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlData, 'text/xml');
    const birthInfo = xmlDoc.getElementsByTagName('BirthInfo')[0];
    const birthDate = parseFloat(birthInfo.getElementsByTagName('BirthDate')[0].textContent);
    const longitude = parseFloat(birthInfo.getElementsByTagName('Longitude')[0].textContent);
    const latitude = parseFloat(birthInfo.getElementsByTagName('Latitude')[0].textContent);
    const timeZone = parseFloat(birthInfo.getElementsByTagName('TimeZone')[0].textContent);
    const city = birthInfo.getElementsByTagName('City')[0].textContent;
    const state = birthInfo.getElementsByTagName('State')[0].textContent;
    const country = birthInfo.getElementsByTagName('Country')[0].textContent;

    // Convert Julian date to UTC
    const julianDateInMs = (birthDate - 2440587.5) * 86400 * 1000;
    const birthDateInUTC = new Date(julianDateInMs - timeZone * 3600000).toDateString();

    const place = [city, state, country].filter(Boolean).join(', ');
    const placeCoords = [latitude, longitude];

    return {
        dob: birthDateInUTC,
        place,
        placeCoords:{'longitude': longitude, 'latitude': latitude},
    };
}


export default function XmlDragAndDrop({onDropped}) {
    const [birthdate, setBirthdate] = useState('');
    const [country, setCountry] = useState('');
    const [xml, setXML] = useState('');

    const handleFileDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const xml = event.target.result;
            setXML(xml);
            const {dob, place, placeCoords} = extractBirthInfo(xml)
            setBirthdate(dob)
            setCountry(place)
            if (onDropped){
                onDropped({dob, place, placeCoords, xml})
            }
        };
        reader.readAsText(file);

    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <div
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
            style={{ border: '1px solid black', padding: '10px', width: '400px' }}
        >
            <h2>Drag and Drop XML file here to extract data</h2>
            <p>Birthdate: {birthdate}</p>
            <p>Country: {country}</p>
        </div>
    );
}
