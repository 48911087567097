import React, { useEffect, useState } from 'react';
import { collection, getDocs } from "firebase/firestore";
import {db} from "../App";

function ViewData() {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const querySnapshot = await getDocs(collection(db, "form-data"));
            const dataArray = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setData(dataArray);
        };

        fetchData();
    }, []);

    return (
        <div>
            <h1>View Data</h1>
            <table>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Date of Birth</th>
                    <th>Time of Birth</th>
                    <th>Place of Birth</th>
                    <th>Question</th>
                    <th>Answer</th>
                </tr>
                </thead>
                <tbody>
                {data.map((formData) => (
                    <tr key={formData.id}>
                        <td>{formData.id}</td>
                        <td>{formData.dob}</td>
                        <td>{formData.time}</td>
                        <td>{formData.place}</td>
                        <td>{formData.question}</td>
                        <td
                            dangerouslySetInnerHTML={{
                                __html: formData.answer
                            }}
                        ></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default ViewData;
